//------------------------------------------------------------------------
// Polyfills are used by multiple modules and need to load first.
// Moving them to a separate script gives us more control over loading.
//------------------------------------------------------------------------

// CustomEvent polyfill (IE 11- and aOS 4.3-)
// https://caniuse.com/#search=CustomEvent
import 'mdn-polyfills/CustomEvent';

// NodeList.prototype.forEach() polyfill (IE 11-)
import 'mdn-polyfills/NodeList.prototype.forEach';

// Object.assign polyfill (IE 11-)
import 'mdn-polyfills/Object.assign';

// Element.prototype.closest polyfill (IE 11-)
import 'mdn-polyfills/Element.prototype.closest';

// We need the Array.from() polyfill for IE 11 because Babel’s
// spread operator polyfill uses it.
// https://github.com/airbnb/react-dates/issues/93#issuecomment-249095993
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
import 'mdn-polyfills/Array.from';
